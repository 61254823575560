import React, { useEffect, useState } from "react";
import UserPageSection from "./Content";
import deodLogo from "../../../image/Logo.png";
import UsdtLogo from "../../../image/tether-logo.png";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import {
  StakeAbiThree,
  StakeAbiTwo,
  StakeAddressThree,
  StakeAddressTwo,
  StakingAbi,
  StakingAddress,
} from "../Abi/Content";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
const Staking = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth({});
  const [refferal, setRefferal] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalToken, setTotalToken] = useState("0");
  const [totalClaimToken, setTotalClaimToken] = useState("0");
  const [availableClaim, setAvailableClaim] = useState("0");
  const [refferalEarn, setRefferalEarn] = useState("0");
  const [stackingData, setStackingData] = useState([]);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [allDepositData, setAllDepositData] = useState([]);
  const loadData = async () => {
    debugger;
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();

      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakingContract = new ethers.Contract(
        StakingAddress,
        StakingAbi,
        signer
      );
      let totalToken = await StakingContract.userTotalTokenStaked(address);
      totalToken = parseFloat(totalToken) / 10 ** 18;
      setTotalToken(totalToken);

      let availableClaim = await StakingContract.availableAmountForClaim(
        address
      );
      availableClaim = parseFloat(availableClaim) / 10 ** 18;
      setAvailableClaim(availableClaim);

      let totalClaimToken = await StakingContract.withdrawnAmountV1(address);
      totalClaimToken = parseFloat(totalClaimToken) / 10 ** 18;
      setTotalClaimToken(totalClaimToken);

      let DepositCounts = await StakingContract.userDepositCounts(address);
      DepositCounts = parseInt(DepositCounts);

      let depositArr = [];

      for (let i = 0; i < DepositCounts; i++) {
        let DepositIds = await StakingContract.userDepositIds(address, i);
        DepositIds = parseInt(DepositIds);
        let DepositAllData = await StakingContract.depositInfo(DepositIds);
        depositArr = [DepositAllData, ...depositArr];
      }
      setAllDepositData(depositArr);
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleGetStackingData = async () => {
    const token = auth?.user?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    try {
      const resp = await axios.get(`/api/v3/user/stakeV3details`, {
        headers,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setStackingData(resp?.data?.data);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const handleClaim = async () => {
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const storeAddress = auth?.user?.address;
      if (address === storeAddress) {
        const StakingContract = new ethers.Contract(
          StakingAddress,
          StakingAbi,
          signer
        );
        const txn = await StakingContract.withdrawTokensV1(address);
        const receipttxn1 = await txn.wait();
        if (receipttxn1.status !== 1) {
          toast.error("Error");
        } else {
          toast.success("Claim successfully");
        }
      } else {
        toast.error("Connect correct wallet");
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
      toast.error(`${JSON.stringify(error.reason)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
    window.location.reload(false);
  };

  useEffect(() => {
    async function handleGetParaswapPrice() {
      if (totalToken == 0) {
        setUsdtPrice(0);
      } else {
        try {
          let x;
          x = totalToken * 10 ** 18;
          let z = new BigNumber(x);
          let a = z.toFixed();
          const Paraswap = `https://api.paraswap.io/prices/?srcToken=0xe77abb1e75d2913b2076dd16049992ffeaca5235&destToken=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&amount=${a}&srcDecimals=18&destDecimals=6&side=SELL&excludeDirectContractMethods=false&network=137&otherExchangePrices=true&partner=paraswap.io&userAddress=0x0000000000000000000000000000000000000000`;
          console.log("Paraswap", a);
          const getParaswapData = await axios.get(Paraswap);
          if (getParaswapData.status === 200) {
            let finalUSDTPrice = getParaswapData?.data?.priceRoute?.destAmount;
            finalUSDTPrice = (finalUSDTPrice / 10 ** 6).toFixed(2);
            setUsdtPrice(finalUSDTPrice);
          }
        } catch (error) {
          console.log("error in deod price paraswap api", error);
        }
      }
    }
    handleGetParaswapPrice();
  }, [totalToken]);

  useEffect(() => {
    async function handleData() {
      await loadData();
    }
    handleData();
  }, []);

  useEffect(() => {
    if (
      auth?.user?.address &&
      auth?.user?.referralAddress &&
      auth?.user?.token
    ) {
      setRefferal(auth?.user?.referralAddress);
      setAddress(auth?.user?.address);
      handleGetStackingData();
    }
  }, [auth]);

  return (
    <div>
      <UserPageSection>
        <div>
          <div className="row"></div>
          <div className="row justify-content-center my-4">
            <div className="col-12 col-md-4">
              <div className="card cus-card-token-ad text-center">
                <div className="card-body">
                  <h6>Total Staked Token</h6>
                </div>
                <p className="mt-1">{totalToken}</p>
                <p className="mt-1">
                  <span>
                    <img src={deodLogo} className="deod-logo" alt="Deod" />
                  </span>
                  {totalToken}
                  {"  "}Deod = {usdtPrice}
                  <span>
                    <img src={UsdtLogo} className="deod-logo" alt="USDT" />
                  </span>{" "}
                  USDT
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="card cus-card-token-ad text-center">
                <div className="card-body">
                  <h6>Available Tokens</h6>
                </div>
                <p className="mt-3">{availableClaim}</p>
                <div className="text-center">
                  <button
                    className="btn connect-btn"
                    // onClick={() => handleClaim()}
                  >
                    Claim Token
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card cus-card-token-ad text-center">
                <div className="card-body">
                  <h6>Claim Token</h6>
                </div>
                <p className="mt-3">{totalClaimToken}</p>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Staked Amount</th>
                  <th scope="col">Days</th>
                  {/* <th scope="col">Percentage</th> */}
                  <th scope="col">Staking Date</th>
                  <th scope="col">Release Date</th>
                </tr>
              </thead>
              <tbody>
                {allDepositData &&
                  allDepositData.map((value, key) => {
                    const convertData = (timestamp) => {
                      const date = new Date(timestamp * 1000);
                      const options = {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      };
                      const formattedDate = date.toLocaleDateString(
                        "en-US",
                        options
                      );
                      return formattedDate;
                    };

                    return (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>{parseFloat(value?.depositAmount) / 10 ** 18}</td>
                        <td>{parseFloat(value?.timePeriodInDays)}</td>
                        <td>
                          {convertData(parseFloat(value?.depositedTimestamp))}
                        </td>
                        <td>
                          {convertData(parseFloat(value?.maturityTimestamp))}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </UserPageSection>
    </div>
  );
};

export default Staking;
